import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'

const routes = [
	{ path: '/debug_login', name: 'debug_login', component: () => import('../views/LoginView.vue') },
	{ path: '/login', name: 'login', component: () => import('../views/SecondAuthLoginView.vue') },
	{ path: '/user_preview/:user_id', component: () => import('../views/user/UserEditView.vue'), meta: { requiresAuth: true } },
	{ path: '/menu/', name: 'dashboard', component: () => import('../views/DashboardView.vue'), meta: { requiresAuth: true },
		children: [
			{ path: 'home', component: () => import('../views/home/HomeView.vue'), meta: { requiresAuth: true }},
			{ path: 'com', component: () => import('../views/com/ComListView.vue'), meta: { requiresAuth: true }},
			{ path: 'officer', component: () => import('../views/officer/OfficerListView.vue'), meta: { requiresAuth: true }},
			{ path: 'user', component: () => import('../views/user/UserListView.vue'), meta: { requiresAuth: true }},
			{ path: 'admin', component: () => import('../views/user/AdminListView.vue'), meta: { requiresAuth: true }},
			{ path: 'user_permission', component: () => import('../views/user/UserPermissionListView.vue'), meta: { requiresAuth: true }},
			{ path: 'user_access_log', component: () => import('../views/user/UserAccessLogListView.vue'), meta: { requiresAuth: true }},
			{ path: 'team', component: () => import('../views/team/TeamListView.vue'), meta: { requiresAuth: true }},
			{ path: 'job', component: () => import('../views/job/JobListView.vue'), meta: { requiresAuth: true }},
			{ path: 'license', component: () => import('../views/license/LicenseListView.vue'), meta: { requiresAuth: true }},
			{ path: 'process', component: () => import('../views/process/ProcessListView.vue'), meta: { requiresAuth: true }},
			{ path: 'gen_value', component: () => import('../views/gen_value/GenValueListView.vue'), meta: { requiresAuth: true }},
			{ path: 'common', component: () => import('../views/common/CommonListView.vue'), meta: { requiresAuth: true }},
			{ path: 'user_chart', component: () => import('../views/user/UserChartView.vue'), meta: { requiresAuth: true }},
			{ path: 'evaluation_start', component: () => import('../views/evaluation/EvaluationStartView.vue'), meta: { requiresAuth: true }},
			{ path: 'evaluation_list/:year/:month/:day', component: () => import('../views/evaluation/EvaluationListView.vue'), meta: { requiresAuth: true }},
			{ path: 'evaluation_list/:year/:month/:day/:user_id/:com_id/', component: () => import('../views/evaluation/EvaluationListView.vue'), meta: { requiresAuth: true }},
			{ path: 'evaluation_edit', component: () => import('../views/evaluation/EvaluationEditView.vue'), meta: { requiresAuth: true }},
			{ path: 'evaluation_simulation_list/:user_id/:com_id', component: () => import('../views/evaluation/EvaluationSimulationListView.vue'), meta: { requiresAuth: true }},
			{ path: 'bonus_start', component: () => import('../views/bonus/BonusStartView.vue'), meta: { requiresAuth: true }},
			{ path: 'bonus_list/:year/:month/:day', component: () => import('../views/bonus/BonusListView.vue'), meta: { requiresAuth: true }},
			{ path: 'bonus_edit', component: () => import('../views/bonus/BonusEditView.vue'), meta: { requiresAuth: true }},
			{ path: 'salary_start', component: () => import('../views/salary/SalaryStartView.vue'), meta: { requiresAuth: true }},
			{ path: 'salary_bulk_edit', component: () => import('../views/salary/SalaryBulkEditView.vue'), meta: { requiresAuth: true }},
			{ path: 'salary_list', component: () => import('../views/salary/SalaryListView.vue'), meta: { requiresAuth: true }},
			{ path: 'salary_user_list', component: () => import('../views/salary/SalaryUserListView.vue'), meta: { requiresAuth: true }},
			{ path: 'salary_user_list/:pay_type_id/:aggre_ym/:com_id', component: () => import('../views/salary/SalaryUserListView.vue'), meta: { requiresAuth: true }},
			{ path: 'premium', component: () => import('../views/premium/PremiumListView.vue'), meta: { requiresAuth: true }},
			{ path: 'imcome', component: () => import('../views/imcome/ImcomeListView.vue'), meta: { requiresAuth: true }},
			{ path: 'tax_withholding_slip', component: () => import('../views/tax_withholding_slip/TaxWithholdingSlipListView.vue'), meta: { requiresAuth: true }},
			{ path: 'tax_withholding_slip_user_list/:fiscal_year/', component: () => import('../views/tax_withholding_slip/TaxWithholdingSlipUserListView.vue'), meta: { requiresAuth: true }},
			{ path: 'bank', component: () => import('../views/bank/BankListView.vue'), meta: { requiresAuth: true }},
			{ path: 'bank_branch/:bank_no', component: () => import('../views/bank/BankBranchListView.vue'), meta: { requiresAuth: true }},
			{ path: 'bank_transfer', component: () => import('../views/bank_transfer/BankTransferListView.vue'), meta: { requiresAuth: true }},
		]
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach((to) => {
	if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
		return { name: "login" };
	}
});

export default router
